import axios from "axios";
import { getToken, getTokenForMultiPart } from "./getToken";

const BASE_URL = process.env.REACT_APP_PUBLIC_API;
const headers = getToken();
const headersForMultiPart = getTokenForMultiPart();

export const getAllUsers = async ({
  userType = "Owner",
  page = 1,
  dateFilter = null,
  sortColumn = null,
  sortDirection = "asc",
}) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/users/all-users`, {
      headers,
      params: {
        userType,
        page,
        dateFilter,
        sortColumn,
        sortDirection,
      },
    });
    return response;
  } catch (error) {
    console.error("Error while calling get blogs api:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};
