import * as yup from "yup";

// Define amenities, activities, and other static lists
export const amenitiesList = [
  "WiFi",
  "AirConditioning",
  "SwimmingPool",
  "Parking",
];
export const naturalFeaturesAndActivitiesList = [
  "Beach",
  "Mountain",
  "Hiking",
  "Fishing",
];

// Default values for creating a new listing
export const emptyListingDefaultValues = {
  listingType: "",
  title: "",
  description: "",
  country: "",
  address: "",
  zipCode: "",
  city: "",
  apartmentFloor: "",
  municipality: "",
  wardNo: "",
  villageName: "",
  locality: [],
  lat: null,
  lng: null,
  availableAmenities: [],
  availableNaturalFeaturesAndActivities: [],
  servesFood: "no",
  isFoodPriceIncludedInTheTotalPrice: "no",
  pricePerNightOfTheRoom: null,
  totalPrice: null,
  meals: [],
  languages: [],
  isDiscoverySite: true,
  smokingAllowed: false,
  childrenAllowed: false,
  partiesAllowed: false,
  petsAllowed: "no",
  additionalFeesForPets: false,
  petFeePrice: null,
  checkInFrom: "",
  checkInUntil: "",
  checkOutFrom: "",
  checkOutUntil: "",
  easyOnboardingCompletion: false,
  stepOneCompletion: false,
  stepTwoCompletion: false,
  stepThreeCompletion: false,
  stepFourCompletion: false,
  stepFiveCompletion: false,
  paymentType: "",
  bookingMode: "",
  depositPercentage: null,
  idType: "",
  idNumber: "",
  listingStatus: "CREATING",
  propertyType: "",
  isPropertyRegistered: false,
  isTaxRateIncluded: false,
  legalPropertyName: "",
  vatId: "",
  payment: [],
  image: null,
  gallery: [],
  units: [],
  campgroundInfo: null,
  individualData: null,
  businessData: null,
  isInformationTrue: false,
  acceptTermsAndConditions: false,
  acceptPrivacyPolicy: false,
  roomPricingType: "Per Room",
};

export const editListingDefaultValues = {
  id: "",
  listingType: "",
  title: "",
  description: "",
  country: "",
  address: "",
  zipCode: "",
  city: "",
  apartmentFloor: "",
  municipality: "",
  wardNo: "",
  villageName: "",
  locality: [],
  lat: null,
  lng: null,
  availableAmenities: [],
  availableNaturalFeaturesAndActivities: [],
  servesFood: "no",
  isFoodPriceIncludedInTheTotalPrice: "no",
  pricePerNightOfTheRoom: null,
  totalPrice: null,
  meals: [],
  languages: [],
  isDiscoverySite: true,
  smokingAllowed: false,
  childrenAllowed: false,
  partiesAllowed: false,
  petsAllowed: "no",
  additionalFeesForPets: false,
  petFeePrice: null,
  checkInFrom: "",
  checkInUntil: "",
  checkOutFrom: "",
  checkOutUntil: "",
  easyOnboardingCompletion: false,
  stepOneCompletion: false,
  stepTwoCompletion: false,
  stepThreeCompletion: false,
  stepFourCompletion: false,
  stepFiveCompletion: false,
  paymentType: "",
  bookingMode: "",
  depositPercentage: null,
  idType: "",
  idNumber: "",
  listingStatus: "CREATING",
  propertyType: "",
  isPropertyRegistered: false,
  isTaxRateIncluded: false,
  legalPropertyName: "",
  vatId: "",
  payment: [],
  image: null,
  gallery: [],
  units: [],
  campgroundInfo: null,
  individualData: null,
  businessData: null,
  isInformationTrue: false,
  acceptTermsAndConditions: false,
  acceptPrivacyPolicy: false,

  roomPricingType: "Per Room",
};

// Validation schema for listing form
export const listingValidationSchema = yup.object().shape({
  listingType: yup.string().required("Listing type is required."),
  title: yup
    .string()
    .required("Title is required.")
    .min(3, "Title must be at least 3 characters."),
  description: yup.string().required("Description is required."),
  country: yup.string().required("Country is required."),
  address: yup.string().required("Address is required."),
  zipCode: yup.string(),
  city: yup.string().required("City is required."),
  lat: yup.number().nullable().required("Latitude is required."),
  lng: yup.number().nullable().required("Longitude is required."),
  pricePerNightOfTheRoom: yup
    .number()
    .nullable()
    .required("Price per night is required."),
  totalPrice: yup.number().nullable(),
  servesFood: yup.string().oneOf(["yes", "no"]),
  isFoodPriceIncludedInTheTotalPrice: yup.string().oneOf(["yes", "no"]),
  availableAmenities: yup.array().of(yup.string()),
  availableNaturalFeaturesAndActivities: yup.array().of(yup.string()),
  checkInFrom: yup.string().required("Check-in time is required."),
  checkInUntil: yup.string().required("Check-in end time is required."),
  checkOutFrom: yup.string().required("Check-out time is required."),
  checkOutUntil: yup.string().required("Check-out end time is required."),
});

export const normalizeEditListing = (listing) => {
  const data = { ...listing };

  // Normalize other fields
  Object.keys(editListingDefaultValues).forEach((key) => {
    if (typeof data[key] === "undefined" || data[key] === null) {
      data[key] = editListingDefaultValues[key];
    }
  });

  return data;
};

// Normalize data for creating or editing a listing
export const normalizeListing = (listing) => {
  const data = { ...listing };

  Object.keys(data).forEach((key) => {
    if (typeof emptyListingDefaultValues[key] === "undefined") {
      delete data[key];
    }
  });

  return data;
};

// Set form values for editing a listing
export const setFormValues = (defaultValues, setValue) => {
  Object.entries(defaultValues).forEach(([key, value]) => {
    setValue(key, value ?? emptyListingDefaultValues[key]);
  });
};

// Normalize payload before submitting to the server
export const normalizeListingPayload = (payload) => {
  return {
    ...payload,
    availableAmenities: Array.isArray(payload.availableAmenities)
      ? payload.availableAmenities
      : payload.availableAmenities.split(", "),
    availableNaturalFeaturesAndActivities: Array.isArray(
      payload.availableNaturalFeaturesAndActivities
    )
      ? payload.availableNaturalFeaturesAndActivities
      : payload.availableNaturalFeaturesAndActivities.split(", "),
    locality: Array.isArray(payload.locality)
      ? payload.locality
      : payload.locality.split(", "),
  };
};
