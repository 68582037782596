import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import { ImageUploadField } from "../ui/ImageUploadField";
import { GalleryUploadField } from "../ui/GalleryUploadField";
import Spinner from "../ui/Spinner";
import GeooLocationSelectorLeaflet from "./GeoLocationSelectorLeaflet";
import { LatLng } from "leaflet";

import {
  Check,
  Loader2,
  Tent,
  CaravanIcon,
  Hotel,
  Home,
  Map,
  Bed,
  Users,
  Utensils,
  Trash2,
  Pencil,
} from "lucide-react";

import {
  activitiesList,
  amenitiesList,
  emptyListingDefaultValues,
  naturalFeaturesList,
  setFormValues,
} from "./utils";
import {
  deleteListingImage,
  uploadListingGallery,
  uploadListingThumbnail,
} from "../../services/api/listing";

const generateUniqueRoomId = () => {
  return Math.floor(10000000 + Math.random() * 90000000); // Generates an 8-digit random number
};

const generateUniquePerPersonId = () => {
  // Generate a random alphanumeric string of length 20.
  // We convert a random number to base-36 and then pad/truncate to 20 characters.
  const randomString = Math.random().toString(36).substr(2);
  // If the string is too short, repeat it; otherwise, take the first 20 characters.
  return (randomString + randomString).substr(0, 20);
};

const availableAmenitiesList = [
  "Electricity",
  "WiFi",
  "Charging Point In Room",
  "Extra Blankets Provided On Request",
  "Fireplace In Dining Area",
  "No Extra Charges for Hot Water",
  "Comfortable Bed",
  "24/7 Hot Water",
  "Attached Bathroom",
  "Possibly Single Room",
  "4G Network",
  "2G Netowrk",
  "Mountain View",
  "River View",
  "Nature View",
];

const roomPricingTypeInfo = {
  PER_ROOM: {
    icon: Bed,
    title: "Per Room",
    description: "Standard room pricing for lodges and hotels.",
  },
  PER_PERSON: {
    icon: Users,
    title: "Per Person",
    description: "Pricing per guest, ideal for shared rooms or group stays.",
  },
  PACKAGE: {
    icon: Utensils,
    title: "Package (Room + Meals)",
    description:
      "Includes both stay and meals, perfect for trekkers and travelers.",
  },
};

export const ListingForm = ({
  defaultValues = emptyListingDefaultValues,
  onSave = () => {},
  updateListing = false,
}) => {
  const navigate = useNavigate();

  const [geoLocation, setGeoLocation] = useState(null); // Initialize as null
  const [isDiscoverySite, setIsDiscoverySite] = useState(true); // Default value is true

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    defaultValues,
  });

  const {
    fields: roomFields,
    append: appendRoom,
    remove: removeRoom,
    update: updateRoom,
  } = useFieldArray({
    control,
    name: "room",
  });

  const lat = watch("lat");
  const lng = watch("lng");

  const roomPricingType = watch("roomPricingType");
  const availableAmenities = watch("availableAmenities");
  const meals = watch("meals");
  const payment = watch("payment");

  const [currentRoom, setCurrentRoom] = useState({
    roomType: "ATTACHED_BATHROOM",
    price: "",
    beddingArrangements: {
      singleBeds: "",
      doubleBeds: "",
    },
    numberOfRooms: "",
    id: "", // This will hold the object ID from the database
    roomId: 0, // 0 indicates a new room (no roomId assigned yet)
    dbId: "",
  });

  const [editIndex, setEditIndex] = useState(null);
  const [validationResult, setValidationResult] = useState(null); // State to hold validation result

  const handleAddRoom = () => {
    // Ensure singleBeds and doubleBeds are numbers, defaulting to 0 if empty
    const sanitizedRoom = {
      ...currentRoom,
      beddingArrangements: {
        singleBeds: Number.isInteger(currentRoom.beddingArrangements.singleBeds)
          ? currentRoom.beddingArrangements.singleBeds
          : 0,
        doubleBeds: Number.isInteger(currentRoom.beddingArrangements.doubleBeds)
          ? currentRoom.beddingArrangements.doubleBeds
          : 0,
      },
    };

    // Validation: At least one bed type should be greater than 0
    if (
      sanitizedRoom.beddingArrangements.singleBeds === 0 &&
      sanitizedRoom.beddingArrangements.doubleBeds === 0
    ) {
      return;
    }

    const newRoom = {
      ...sanitizedRoom,
      roomId:
        sanitizedRoom.roomId !== 0
          ? sanitizedRoom.roomId
          : generateUniqueRoomId(),
      dbId: sanitizedRoom.dbId || "", // Preserve DB ID
    };

    if (sanitizedRoom.roomId !== 0) {
      const roomIndex = roomFields.findIndex(
        (room) => room.roomId === sanitizedRoom.roomId
      );
      if (roomIndex !== -1) {
        updateRoom(roomIndex, newRoom);
      }
      setEditIndex(null);
    } else {
      appendRoom(newRoom);
    }

    // Reset form
    setCurrentRoom({
      roomType: "ATTACHED_BATHROOM",
      price: "",
      beddingArrangements: {
        singleBeds: "",
        doubleBeds: "",
      },
      numberOfRooms: "",
      id: "",
      dbId: "", // Reset dbId
      roomId: 0,
    });
  };

  const handleEdit = (roomId) => {
    const room = roomFields.find((room) => room.roomId === roomId);

    if (room) {
      setCurrentRoom({
        roomType: room.roomType || "ATTACHED_BATHROOM",
        price: room.price || 1,
        beddingArrangements: {
          singleBeds: room.beddingArrangements?.singleBeds || 0,
          doubleBeds: room.beddingArrangements?.doubleBeds || 0,
        },
        numberOfRooms: room.numberOfRooms || 1,
        id: room.id || "", // Check if this is wrong
        roomId: room.roomId,
        dbId: room.dbId || "",
      });

      setEditIndex(roomId);
    } else {
      console.error("Room not found for editing.");
    }
  };

  const {
    fields: perPersonFields,
    append: appendPerPersonPricing,
    remove: removePerPersonPricing,
    update: updatePerPersonPricing,
  } = useFieldArray({
    control,
    name: "pricingDetails.perPerson",
  });

  const [perPersonPricing, setPerPersonPricing] = useState({
    pricePerPerson: "",
    roomType: "ATTACHED_BATHROOM",
    availableAmenities: [],
    perPersonId: "",
  });

  const [perPersonEditIndex, setPerPersonEditIndex] = useState(null);

  const handleAddPerPerson = () => {
    // Spread the current perPersonPricing state
    const sanitizedPricing = {
      ...perPersonPricing,
    };

    // Validation: Ensure price is greater than 0
    if (sanitizedPricing.pricePerPerson <= 0) {
      return;
    }

    // Since we're not using Zod, we don't perform any schema validation here.
    // If validation passes, proceed to add or update pricing.
    const newPricing = {
      ...sanitizedPricing,
      perPersonId:
        sanitizedPricing.perPersonId && sanitizedPricing.perPersonId !== ""
          ? sanitizedPricing.perPersonId
          : generateUniquePerPersonId(),
    };

    // If we already have an id (editing an existing entry), update it.
    if (sanitizedPricing.perPersonId && sanitizedPricing.perPersonId !== "") {
      const perPersonIndex = perPersonFields.findIndex(
        (person) => person.perPersonId === sanitizedPricing.perPersonId
      );
      if (perPersonIndex !== -1) {
        updatePerPersonPricing(perPersonIndex, newPricing);
      }
      setPerPersonEditIndex(null);
    } else {
      // Otherwise, append the new pricing entry
      appendPerPersonPricing(newPricing);
    }

    // Reset form state
    setPerPersonPricing({
      pricePerPerson: "",
      roomType: "ATTACHED_BATHROOM",
      availableAmenities: [],
      perPersonId: "",
    });
  };

  const handleEditPerPerson = (perPersonId) => {
    const pricing = perPersonFields.find(
      (person) => person.perPersonId === perPersonId
    );

    if (pricing) {
      setPerPersonPricing({
        pricePerPerson: pricing.pricePerPerson || 1,
        roomType: pricing.roomType || "ATTACHED_BATHROOM",
        perPersonId: pricing.perPersonId,
        availableAmenities: pricing.availableAmenities || [],
      });

      setPerPersonEditIndex(perPersonId);
    } else {
      console.error("Per person pricing not found for editing.");
    }
  };

  const [packagePricing, setPackagePricing] = useState({
    priceOfVegPackage: 0,
    priceOfNonVegPackage: 0,
    isBreakfastIncluded: false,
    availableAmenities: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal For Adding Meal
  const [editingMealIndex, setEditingMealIndex] = useState(null);
  const [newMeal, setNewMeal] = useState({
    mealType: "Veg-Khana",
    price: 0,
  });

  const openModal = (index = null) => {
    setIsModalOpen(true);
    setEditingMealIndex(index);
    if (index !== null && meals) {
      const selectedMeal = meals[index];
      setNewMeal({
        mealType: selectedMeal.mealType || "Veg-Khana",
        price: selectedMeal.price || 0,
      });
    } else {
      setNewMeal({
        mealType: "Veg-Khana",
        price: 0,
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingMealIndex(null);
  };

  const handleSaveMeal = () => {
    const updatedMeals = [...(meals || [])];
    if (editingMealIndex !== null) {
      updatedMeals[editingMealIndex] = newMeal;
    } else {
      updatedMeals.push(newMeal);
    }
    setValue("pricingDetails.meals", updatedMeals);
    closeModal();
  };

  const handleDeleteMeal = (index) => {
    const updatedMeals = (meals || []).filter((_, i) => i !== index);
    setValue("pricingDetails.meals", updatedMeals);
  };

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // Modal For Adding Payment
  const [editingPaymentIndex, setEditingPaymentIndex] = useState(null);
  const [newPayment, setNewPayment] = useState({
    paymentMethod: "",
    bankName: "",
    bankAccountName: "",
    bankAccountNumber: "",
    eseweOrKhaltiNumber: "",
  });

  const banks = [
    // Commercial Banks
    "Nepal Bank Ltd.",
    "Agriculture Development Bank Ltd.",
    "Nabil Bank Ltd.",
    "Nepal Investment Mega Bank Ltd.",
    "Standard Chartered Bank Nepal Ltd.",
    "Himalayan Bank Ltd.",
    "Nepal SBI Bank Ltd.",
    "Everest Bank Ltd.",
    "Kumari Bank Ltd.",
    "Laxmi Sunrise Bank Ltd.",
    "Citizens Bank International Ltd.",
    "Prime Commercial Bank Ltd.",
    "Sanima Bank Ltd.",
    "Machhapuchhre Bank Ltd.",
    "NIC Asia Bank Ltd.",
    "Global IME Bank Ltd.",
    "NMB Bank Ltd.",
    "Prabhu Bank Ltd.",
    "Siddhartha Bank Ltd.",
    "Rastriya Banijya Bank Ltd.",

    // Development Banks
    "Narayani Development Bank Ltd.",
    "Karnali Development Bank Ltd.",
    "Excel Development Bank Ltd.",
    "Miteri Development Bank Ltd.",
    "Muktinath Bikas Bank Ltd.",
    "Corporate Development Bank Ltd.",
    "Sindhu Bikas Bank Ltd.",
    "Salapa Bikash Bank Ltd.",
    "Green Development Bank Ltd.",
    "Sangrila Development Bank Ltd.",
    "Shine Resunga Development Bank Ltd.",
    "Jyoti Bikas Bank Ltd.",
    "Garima Bikas Bank Ltd.",
    "Mahalaxmi Bikas Bank Ltd.",
    "Lumbini Bikas Bank Ltd.",
    "Kamana Sewa Bikas Bank Ltd.",
    "Saptakoshi Development Bank Ltd.",
  ];

  const openPaymentModal = (index = null) => {
    setIsPaymentModalOpen(true);
    setEditingPaymentIndex(index);
    if (index !== null && payment) {
      const selectedPayment = payment[index];
      setNewPayment({
        paymentMethod: selectedPayment.paymentMethod || "",
        bankName: selectedPayment.bankName || "",
        bankAccountName: selectedPayment.bankAccountName || "",
        bankAccountNumber: selectedPayment.bankAccountNumber || "",
        eseweOrKhaltiNumber: selectedPayment.eseweOrKhaltiNumber || "",
      });
    } else {
      setNewPayment({
        paymentMethod: "",
        bankName: "",
        bankAccountName: "",
        bankAccountNumber: "",
        eseweOrKhaltiNumber: "",
      });
    }
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setEditingPaymentIndex(null);
  };

  const handleSavePayment = () => {
    const updatedPayment = [...(payment || [])];
    if (editingPaymentIndex !== null) {
      updatedPayment[editingPaymentIndex] = newPayment;
    } else {
      updatedPayment.push(newPayment);
    }
    setValue("termsDetails.payment", updatedPayment);
    closePaymentModal();
  };

  const handleDeletePayment = (index) => {
    const updatedPayment = (payment || []).filter((_, i) => i !== index);
    setValue("termsDetails.payment", updatedPayment);
  };

  const onSubmit = async (payload) => {
    try {
      const data = payload;
      reset();
      navigate("/listings");
      return onSave(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      setValue(key, defaultValues[key]);
    });
    setFormValues(defaultValues, setValue);
  }, [defaultValues, setValue]);

  useEffect(() => {
    if (geoLocation) {
      setValue("lat", geoLocation.lat, { shouldValidate: true });
      setValue("lng", geoLocation.lng, { shouldValidate: true });
    }
  }, [geoLocation, setValue]);

  const handleLocationSelect = (latlng) => {
    console.log(latlng, "latlnglatlnglatlng"); // Check if this is the correct value
    setGeoLocation(latlng); // Update geoLocation with the new selection
  };

  const handleToggle = () => {
    const newValue = !isDiscoverySite;
    setIsDiscoverySite(newValue);
    setValue("isDiscoverySite", newValue); // Update form state in react-hook-form
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            {...register("title")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.title && (
            <p className="text-sm text-red-500">{errors.title.message}</p>
          )}
        </div>

        {/* Is Discovery Site Toggle */}
        <div className="flex items-center mt-4">
          <label
            htmlFor="isDiscoverySite"
            className="block text-sm font-medium text-gray-700 mr-4"
          >
            Is Discovery Site
          </label>
          <button
            type="button"
            onClick={handleToggle}
            className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200 focus:outline-none ${
              isDiscoverySite ? "bg-indigo-500" : "bg-gray-300"
            }`}
          >
            <span
              className={`inline-block w-4 h-4 transform bg-white rounded-full shadow-lg transition-transform duration-200 ${
                isDiscoverySite ? "translate-x-6" : "translate-x-1"
              }`}
            />
          </button>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Listing Type
          </label>
          <select
            {...register("listingType")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select Listing Type</option>
            <option value="CAMPGROUND">CAMPGROUND</option>
            <option value="CAMPER">CAMPER</option>
            <option value="CAMPSTAY">CAMPSTAY</option>
            <option value="HOTEL">HOTEL</option>
            <option value="HOMESTAY">HOMESTAY</option>
            <option value="TOUR_PACKAGE">TOUR_PACKAGE</option>
          </select>
          {errors.listingType && (
            <p className="text-sm text-red-500">{errors.listingType.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            {...register("description")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
          {errors.description && (
            <p className="text-sm text-red-500">{errors.description.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Country
          </label>
          <input
            type="text"
            {...register("country")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.country && (
            <p className="text-sm text-red-500">{errors.country.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            City
          </label>
          <input
            type="text"
            {...register("city")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.city && (
            <p className="text-sm text-red-500">{errors.city.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Mmunicipality (Gaupalika / Nagarpalika)
          </label>
          <input
            type="text"
            {...register("municipality")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.municipality && (
            <p className="text-sm text-red-500">
              {errors.municipality.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ward number
          </label>
          <input
            type="text"
            {...register("wardNo")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.wardNo && (
            <p className="text-sm text-red-500">{errors.wardNo.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Village Name
          </label>
          <input
            type="text"
            {...register("villageName")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.villageName && (
            <p className="text-sm text-red-500">{errors.villageName.message}</p>
          )}
        </div>

        <div className="col-span-2">
          <p>
            This is the location we'll show guests on our site. Move the map to
            find the exact location of your property, then click to drop the
            pin.
          </p>
          <GeooLocationSelectorLeaflet
            onLocationSelect={handleLocationSelect}
            initialLocation={geoLocation || null} // Pass geoLocation as initialLocation
          />

          <div className="flex flex-col lg:flex-row justify-between mt-6 space-y-4 lg:space-y-0">
            {/* Readonly Lat/Lng Fields */}
            <div className="flex flex-col space-y-2">
              <label htmlFor="lat">Latitude</label>
              <input
                id="lat"
                type="text"
                value={lat || ""}
                className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="lng">Longitude</label>
              <input
                id="lng"
                type="text"
                value={lng || ""}
                className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Price Per night
          </label>
          <input
            type="number"
            min="0"
            step="0.01"
            {...register("pricePerNightOfTheRoom")}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {errors.pricePerNightOfTheRoom && (
            <p className="text-sm text-red-500">
              {errors.pricePerNightOfTheRoom.message}
            </p>
          )}
        </div>

        <div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-4">
              What is the Pricing Type?
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4">
              {Object.entries(roomPricingTypeInfo).map(([type, info]) => {
                const Icon = info.icon;
                return (
                  <div
                    key={type}
                    onClick={() =>
                      setValue("roomPricingType", type, {
                        shouldValidate: true,
                      })
                    }
                    className={`cursor-pointer rounded-lg border-2 p-2 sm:p-4 transition-all hover:shadow-md ${
                      roomPricingType === type
                        ? "border-green-500 bg-green-50"
                        : "border-gray-200 hover:border-green-200"
                    }`}
                  >
                    <div className="flex flex-col items-center text-center space-y-1 sm:space-y-2">
                      <Icon
                        className={`h-6 w-6 sm:h-8 sm:w-8 ${
                          roomPricingType === type
                            ? "text-green-600"
                            : "text-gray-600"
                        }`}
                      />
                      <h3 className="font-medium text-sm sm:text-base">
                        {info.title}
                      </h3>
                      <p className="text-[10px] sm:text-xs text-gray-500">
                        {info.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

            {roomPricingType === "PER_ROOM" && (
              <div className="space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Room Type
                    </label>
                    <select
                      value={currentRoom.roomType}
                      onChange={(e) =>
                        setCurrentRoom({
                          ...currentRoom,
                          roomType: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                    >
                      <option value="">Select Room Type</option>
                      <option value="ATTACHED_BATHROOM">
                        Attached Bathroom
                      </option>
                      <option value="NON_ATTACHED_BATHROOM">
                        Non Attached Bathroom
                      </option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Price
                    </label>
                    <input
                      type="number"
                      value={currentRoom.price}
                      onChange={(e) =>
                        setCurrentRoom({
                          ...currentRoom,
                          price: Number(e.target.value),
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                      min="1"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Single Beds
                    </label>
                    <input
                      type="number"
                      value={currentRoom.beddingArrangements.singleBeds}
                      onChange={(e) =>
                        setCurrentRoom({
                          ...currentRoom,
                          beddingArrangements: {
                            ...currentRoom.beddingArrangements,
                            singleBeds: Number(e.target.value),
                          },
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                      min="0"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Double Beds
                    </label>
                    <input
                      type="number"
                      value={currentRoom.beddingArrangements.doubleBeds}
                      onChange={(e) =>
                        setCurrentRoom({
                          ...currentRoom,
                          beddingArrangements: {
                            ...currentRoom.beddingArrangements,
                            doubleBeds: Number(e.target.value),
                          },
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                      min="0"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Number of Rooms
                    </label>
                    <input
                      type="number"
                      value={currentRoom.numberOfRooms}
                      onChange={(e) =>
                        setCurrentRoom({
                          ...currentRoom,
                          numberOfRooms: Number(e.target.value),
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                      min="1"
                    />
                  </div>
                </div>

                <button
                  type="button"
                  onClick={handleAddRoom}
                  className="w-full bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 transition-colors"
                >
                  {currentRoom.roomId !== 0 ? "Update Room" : "Add Room"}
                </button>

                <div className="bg-white rounded-lg shadow-md overflow-x-auto">
                  <table className="w-full table-auto">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Room Type
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Price
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Single Beds
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Double Beds
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          No. of Rooms
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {roomFields && roomFields.length > 0 ? (
                        roomFields.map((room, index) => (
                          <tr key={room.roomId}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {room.roomType}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {room.price}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {room.beddingArrangements.singleBeds}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {room.beddingArrangements.doubleBeds}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {room.numberOfRooms}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {editIndex === room.roomId ? (
                                <span className="text-blue-600 font-semibold">
                                  Editing
                                </span>
                              ) : (
                                <div className="flex space-x-4">
                                  <button
                                    onClick={() => handleEdit(room.roomId)}
                                    className="text-green-600 hover:text-green-800"
                                  >
                                    <Pencil className="w-5 h-5" />
                                  </button>
                                  <button
                                    onClick={() => removeRoom(index)}
                                    className="text-red-600 hover:text-red-800"
                                  >
                                    <Trash2 className="w-5 h-5" />
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            className="border px-4 py-2 text-center text-gray-500"
                          >
                            No rooms added yet.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* Amenities Selection (Global) */}
                <h3 className="text-xl font-bold">
                  Select Available Amenities
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {availableAmenitiesList.map((amenity) => (
                    <div key={amenity} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id={amenity}
                        value={amenity}
                        checked={availableAmenities.includes(amenity)}
                        onChange={(e) => {
                          const updatedAmenities = e.target.checked
                            ? [...availableAmenities, amenity]
                            : availableAmenities.filter(
                                (item) => item !== amenity
                              );

                          setValue("availableAmenities", updatedAmenities);
                        }}
                        className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 focus:ring-2"
                      />
                      <label
                        htmlFor={amenity}
                        className="text-gray-700 cursor-pointer"
                      >
                        {amenity}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {roomPricingType === "PER_PERSON" && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Price Per Person
                    </label>
                    <input
                      type="number"
                      value={perPersonPricing.pricePerPerson}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setPerPersonPricing((prev) => ({
                          ...prev,
                          pricePerPerson: isNaN(value) ? 0 : value, // Ensuring the value is valid
                        }));
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                      min="1"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Room Type
                    </label>
                    <select
                      value={perPersonPricing.roomType}
                      onChange={(e) =>
                        setPerPersonPricing({
                          ...perPersonPricing,
                          roomType: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
                    >
                      <option value="">Select Room Type</option>
                      <option value="ATTACHED_BATHROOM">
                        Attached Bathroom
                      </option>
                      <option value="NON_ATTACHED_BATHROOM">
                        Non Attached Bathroom
                      </option>
                    </select>
                  </div>

                  <button
                    type="button"
                    onClick={handleAddPerPerson}
                    className="w-full bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 transition-colors"
                  >
                    {perPersonPricing.perPersonId &&
                    perPersonPricing.perPersonId !== ""
                      ? "Update Pricing"
                      : "Add Pricing"}
                  </button>
                </div>

                <div className="bg-white rounded-lg shadow-md overflow-x-auto">
                  <table className="w-full table-auto">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Room Type
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Price Per Person
                        </th>

                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {perPersonFields && perPersonFields.length > 0 ? (
                        perPersonFields.map((pricing, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {pricing.roomType}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {pricing.pricePerPerson}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              {perPersonEditIndex === pricing.perPersonId ? (
                                <span className="text-blue-600 font-semibold">
                                  Editing
                                </span>
                              ) : (
                                <div className="flex space-x-4">
                                  <button
                                    onClick={() =>
                                      handleEditPerPerson(pricing.perPersonId)
                                    }
                                    className="text-green-600 hover:text-green-800"
                                  >
                                    <Pencil className="w-5 h-5" />
                                  </button>
                                  <button
                                    onClick={() =>
                                      removePerPersonPricing(index)
                                    }
                                    className="text-red-600 hover:text-red-800"
                                  >
                                    <Trash2 className="w-5 h-5" />
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={4}
                            className="border px-4 py-2 text-center text-gray-500"
                          >
                            No per person pricing added yet.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <h3 className="text-xl font-bold">
                  Select Available Amenities
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {availableAmenitiesList.map((amenity) => (
                    <div key={amenity} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id={amenity}
                        value={amenity}
                        checked={perPersonPricing.availableAmenities.includes(
                          amenity
                        )}
                        onChange={(e) => {
                          const updatedAmenities = e.target.checked
                            ? [...perPersonPricing.availableAmenities, amenity]
                            : perPersonPricing.availableAmenities.filter(
                                (item) => item !== amenity
                              );
                          setPerPersonPricing((prev) => ({
                            ...prev,
                            availableAmenities: updatedAmenities,
                          }));
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                      />
                      <label
                        htmlFor={amenity}
                        className="text-gray-700 cursor-pointer"
                      >
                        {amenity}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {roomPricingType === "PACKAGE" && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Price of Veg Package */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Price of Veg Package
                    </label>
                    <input
                      type="number"
                      value={packagePricing.priceOfVegPackage}
                      onChange={(e) =>
                        setPackagePricing({
                          ...packagePricing,
                          priceOfVegPackage: Number(e.target.value),
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                      min="0"
                    />
                  </div>

                  {/* Price of Non-Veg Package */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Price of Non-Veg Package
                    </label>
                    <input
                      type="number"
                      value={packagePricing.priceOfNonVegPackage}
                      onChange={(e) =>
                        setPackagePricing({
                          ...packagePricing,
                          priceOfNonVegPackage: Number(e.target.value),
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                      min="0"
                    />
                  </div>
                </div>

                {/* Is Breakfast Included */}
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="isBreakfastIncluded"
                    checked={packagePricing.isBreakfastIncluded}
                    onChange={(e) =>
                      setPackagePricing({
                        ...packagePricing,
                        isBreakfastIncluded: e.target.checked,
                      })
                    }
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="isBreakfastIncluded"
                    className="text-gray-700 cursor-pointer"
                  >
                    Is Breakfast Included?
                  </label>
                </div>

                {/* Available Amenities */}
                <h3 className="text-xl font-bold">
                  Select Available Amenities
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {availableAmenitiesList.map((amenity) => (
                    <div key={amenity} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id={amenity}
                        value={amenity}
                        checked={packagePricing.availableAmenities.includes(
                          amenity
                        )}
                        onChange={(e) => {
                          setPackagePricing({
                            ...packagePricing,
                            availableAmenities: e.target.checked
                              ? [...packagePricing.availableAmenities, amenity]
                              : packagePricing.availableAmenities.filter(
                                  (item) => item !== amenity
                                ),
                          });
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                      />
                      <label
                        htmlFor={amenity}
                        className="text-gray-700 cursor-pointer"
                      >
                        {amenity}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-6">Food Menu & Details</h2>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-4">
                Please add details about your food offerings and menu.
              </label>

              {/* Add Meal Button */}
              <div className="mt-4">
                <button
                  type="button"
                  onClick={() => openModal()}
                  className="bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 transition-colors mb-6"
                >
                  Add Meal
                </button>
              </div>

              <div className="bg-white rounded-lg shadow-md overflow-x-auto">
                <table className="w-full table-auto">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Meal Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Meal Price
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {meals && meals.length > 0 ? (
                      meals.map((meal, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {meal.mealType}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {meal.price}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex space-x-4">
                              <button
                                onClick={() => openModal(index)}
                                className="text-green-600 hover:text-green-800"
                              >
                                <Pencil className="w-5 h-5" />
                              </button>
                              <button
                                onClick={() => handleDeleteMeal(index)}
                                className="text-red-600 hover:text-red-800"
                              >
                                <Trash2 className="w-5 h-5" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          className="border px-4 py-2 text-center text-gray-500"
                        >
                          No meals added yet.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Add Meals Modal */}
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                  <h3 className="text-xl font-semibold">
                    {editingMealIndex !== null ? "Edit Meal" : "Add Meal"}
                  </h3>
                  <div className="space-y-4 mt-4">
                    <div>
                      <label className="block font-medium">Meal Type</label>
                      <select
                        value={newMeal.mealType}
                        onChange={(e) =>
                          setNewMeal({ ...newMeal, mealType: e.target.value })
                        }
                        className="w-full border px-3 py-2 rounded-md"
                      >
                        <option value="Veg-Khana">Veg-Khana</option>
                        <option value="Non-VegKhana">Non-VegKhana</option>
                        <option value="Breakfast">Breakfast</option>
                        <option value="Lunch">Lunch</option>
                        <option value="Dinner">Dinner</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div>
                      <label className="block font-medium">Price</label>
                      <input
                        type="number"
                        value={newMeal.price}
                        onChange={(e) =>
                          setNewMeal({
                            ...newMeal,
                            price: Number(e.target.value),
                          })
                        }
                        className="w-full border px-3 py-2 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="mt-6 flex justify-end space-x-4">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="px-4 py-2 bg-gray-400 text-white rounded-md"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleSaveMeal}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Thumbnail Image
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateListing ? (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateListing}
                  id={defaultValues.id}
                  onUpload={uploadListingThumbnail}
                  onDelete={deleteListingImage}
                  defaultValues={defaultValues}
                />
              ) : (
                <ImageUploadField
                  control={control}
                  multipleUploadAllowed={false}
                  name="thumbnail"
                  updateAction={updateListing}
                  defaultValues={defaultValues}
                />
              )}
            </div>
          </div>
        </div>

        <div className="pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
          >
            Gallery Images
          </label>
          <div className="mt-2 sm:col-span-2 sm:mt-0">
            <div className="max-w-xl">
              {updateListing ? (
                <GalleryUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateListing}
                  id={defaultValues.id}
                  onUpload={uploadListingGallery}
                  onDelete={deleteListingImage}
                />
              ) : (
                <GalleryUploadField
                  control={control}
                  multipleUploadAllowed={true}
                  name="gallery"
                  updateAction={updateListing}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500"
          >
            {isSubmitting ? <Spinner /> : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
};
