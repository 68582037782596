import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  PencilSquareIcon,
  TrashIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@heroicons/react/20/solid";
import { getAllUsers } from "../../services/api/users";

export function List() {
  const [records, setRecords] = useState([]);
  const [userType, setUserType] = useState("Owner");
  const [dateFilter, setDateFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndSetRecords = async () => {
      const res = await getAllUsers({
        userType,
        page: currentPage,
        dateFilter,
        sortColumn,
        sortDirection,
      });
      if (res.status === 200) {
        const data = res?.data;
        setRecords(data);
        setTotalResults(data.response.payload.pagination.total);
      }
    };
    fetchAndSetRecords();
  }, [userType, currentPage, dateFilter, sortColumn, sortDirection]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleUserType = (event) => {
    setUserType(event.target.value);
    setCurrentPage(1);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalResults / 10)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Users
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all users with their full details.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => navigate("/blog/form")}
            className="block px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500"
          >
            Add Users
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="flex items-center space-x-6 mt-4">
        <div>
          <label
            htmlFor="userType"
            className="block text-sm font-medium text-gray-700"
          >
            Filter by User Type:
          </label>
          <select
            id="userType"
            name="userType"
            value={userType}
            onChange={handleUserType}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
          >
            <option value="Owner">Owner</option>
            <option value="User">User</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="dateFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Filter by Date:
          </label>
          <select
            id="dateFilter"
            name="dateFilter"
            value={dateFilter || ""}
            onChange={handleDateFilterChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
          >
            <option value="">All Dates</option>
            <option value="createdAtAsc">Oldest First (Created)</option>
            <option value="createdAtDesc">Newest First (Created)</option>
            <option value="updatedAtAsc">Oldest First (Updated)</option>
            <option value="updatedAtDesc">Newest First (Updated)</option>
          </select>
        </div>
      </div>

      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    onClick={() => handleSort("firstName")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    First Name{" "}
                    {sortColumn === "firstName" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort("lastName")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Last Name{" "}
                    {sortColumn === "lastName" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort("createdAt")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Created At{" "}
                    {sortColumn === "createdAt" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort("email")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Email{" "}
                    {sortColumn === "email" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort("phoneNumber")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Phone Number{" "}
                    {sortColumn === "phoneNumber" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort("otpCode")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    OTP Code{" "}
                    {sortColumn === "otpCode" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort("otpExpiry")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    OTP Expiry{" "}
                    {sortColumn === "otpExpiry" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSort("registrationCompletion")}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Registration Complete{" "}
                    {sortColumn === "registrationCompletion" &&
                      (sortDirection === "asc" ? (
                        <ArrowUpIcon className="w-4 h-4 inline ml-1" />
                      ) : (
                        <ArrowDownIcon className="w-4 h-4 inline ml-1" />
                      ))}
                  </th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {records.response?.data?.map(
                  ({
                    id,
                    firstName,
                    lastName,
                    createdAt,
                    credential,
                  }) => (
                    <tr key={id}>
                      <td className="px-3 py-4 text-sm text-gray-900 whitespace-nowrap sm:pl-0">
                        {firstName}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        {lastName}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        {new Date(createdAt).toLocaleString()}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        {credential.email}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        {credential.phoneNumber}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        {credential.otpCode}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        {credential.otpExpiry
                          ? new Date(credential.otpExpiry).toLocaleString()
                          : "N/A"}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900">
                        {credential.registrationCompletion ? "Yes" : "No"}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900 flex space-x-2">
                        <button
                          onClick={() => navigate(`/blog/${id}/view`)}
                          className="p-1 text-indigo-600 rounded-full hover:bg-gray-200"
                        >
                          <EyeIcon className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => navigate(`/blog/${id}/edit`)}
                          className="p-1 text-indigo-600 rounded-full hover:bg-gray-200"
                        >
                          <PencilSquareIcon className="w-5 h-5" />
                        </button>
                        <button className="p-1 text-red-600 rounded-full hover:bg-gray-200">
                          <TrashIcon className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Previous
              </button>
              <span className="text-sm text-gray-700">Page {currentPage}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === Math.ceil(totalResults / 10)}
                className="px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
